import ls from "localstorage-slim";

// 获取埋点相关信息
export const getTrackStorage = () => {
	return (
		ls.get("dh-track-info") || {
			isClickAfcAd: false,
			viewedChapterCount: 0,
			readDuration: 0,
			isClickVignetteAd: false,
			isReportAddToWishList: false,
			isReportAddPaymentInfo: false,
			isReportCompletePayment: false,
			isReportSubscribe: false,
			isReportInitiateCheckout: false,
			isReportSubmitForm: false,
			isReportCompleteRegistration: false,
			isReportPlaceAnOrder: false,
			viewedPageCount: 0
		}
	);
};

// 存储埋点相关信息
export const setTrackStorage = (data) => {
	const oldData = getTrackStorage();
	let newData = data;

	// 如果本地数据不存在，但是设置的是readDuration，首次不设置
	if (!ls.get("dh-track-info") && data.readDuration) {
		newData = {};
		window.readDuration = 1;
	}

	ls.set(
		"dh-track-info",
		{
			...oldData,
			...newData,
		},
		// 过期时间一个小时
		{
			ttl: 60 * 60,
		}
	);
};

// 更新访问的页面次数信息
export const updateViewedPageCountStroage = () => {
	window.viewedPageCount += 1

	// 当前访问页面次数大于等于设定的目标值
	if (window.viewedPageCount >= window.viewedCountTarget) {
		// 点击过afc广告
		if (window.isClickAfcAd) {
			// 上报事件
			ttq.track('AddToWishlist')
			console.log('AddToWishlist: Viewed page count', window.viewedPageCount)
		}
	}

	// 次数+1
	setTrackStorage({
		viewedPageCount: window.viewedPageCount
	})
}