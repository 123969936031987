<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getTrackStorage, setTrackStorage, updateViewedPageCountStroage } from '@/utils/storage'

// 是否点击了afs广告
let isClickAfcInner = false
// 是否点击了穿插广告
let isClickVignetteAdInner = false
let grayPercent = null

let timer = null

export default {
  name: 'App',

  mounted() {
    this.getGrayPercent()

    // 从本地取出埋点信息，保证整个周期埋点的完整性
    const { isClickAfcAd, viewedChapterCount, readDuration, isClickVignetteAd, isReportAddToWishList, isReportAddPaymentInfo, isReportCompletePayment, isReportSubscribe, isReportInitiateCheckout, isReportSubmitForm, isReportCompleteRegistration, isReportPlaceAnOrder, viewedPageCount } = getTrackStorage()

    window.isClickAfcAd = isClickAfcAd
    // 共阅读的章节数，默认为1
    window.viewedChapterCount = viewedChapterCount;
    // 共阅读时长，计算停留时长，单位秒
    window.readDuration = readDuration;
    // 整个阅读周期是否点击了穿插广告
    window.isClickVignetteAd = isClickVignetteAd;
    // 是否上报过了加入心愿单事件
    window.isReportAddToWishList = isReportAddToWishList;
    // 是否上报过了添加支付信息事件
    window.isReportAddPaymentInfo = isReportAddPaymentInfo;
    // 是否上报过了完成支付事件
    window.isReportCompletePayment = isReportCompletePayment;
    // 是否上报过了订阅事件
    window.isReportSubscribe = isReportSubscribe;
    // 是否上报过了开始结账事件
    window.isReportInitiateCheckout = isReportInitiateCheckout;
    // 是否上报提交表单事件
    window.isReportSubmitForm = isReportSubmitForm
    // 是否上报完成注册事件
    window.isReportCompleteRegistration = isReportCompleteRegistration
    // 是否上报完成注册事件
    window.isReportPlaceAnOrder = isReportPlaceAnOrder
    // 访问的页面次数
    window.viewedPageCount = viewedPageCount
    // 设定的访问目标值
    window.viewedCountTarget = 15

    timer = setInterval(() => {
      // 统计阅读时长
      window.readDuration++

      setTrackStorage({
        readDuration: window.readDuration
      })

      if ((window.readDuration / 60) >= 40) {
        // 一个用户周期内只上报一次
        // if (!window.isReportAddToWishList) {
        //  ttq.track('AddToWishlist')
        //  window.isReportAddToWishList = true
        //  setTrackStorage({
        //    isReportAddToWishList: true
        //  })
        //  console.log('AddToWishlist: stay 40 minutes')
        // }

        if (window.isClickAfcAd) {
          if (!window.isReportAddPaymentInfo) {
            ttq.track('AddPaymentInfo')
            window.isReportAddPaymentInfo = true
            setTrackStorage({
              isReportAddPaymentInfo: true
            })
            console.log('AddPaymentInfo')
          }

          if (window.viewedChapterCount >= 40) {
            // 阅读超过8篇文章并且点击过afc广告
            if (!window.isReportCompletePayment) {
              ttq.track("CompletePayment");
              window.isReportCompletePayment = true
              setTrackStorage({
                isReportCompletePayment: true
              })
              console.log(
                "CompletePayment: view more than 8 and click afc and stay more than 40 minute"
              );
            }
          }
        }
      }
    }, 1000);

    // App组件mounted一次表示页面跳转了一次，即用户访问了一次页面
    updateViewedPageCountStroage()

    this.visibilityChangeListener()
    this.track()
  },

  unmounted() {
    clearInterval(timer)
  },

  methods: {
    // 监听页面显隐
    visibilityChangeListener() {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          console.log('window hide', isClickAfcInner)

          gtag('event', "page_hidden")

          if (isClickAfcInner) {
            const { AddToCart = 100 } = grayPercent || {}
            const random = Math.random() * 100
            const { pathname } = window.location
            gtag("event", "click_afc", {
              page: pathname === '/history' ? 'history_page' : pathname === '/' ? 'home_page' : 'content_page'
            });

            // 点击afc广告事件
            if (random < AddToCart) {
              ttq.track('AddToCart')
            }
            window.isClickAfcAd = true
            setTrackStorage({
              isClickAfcAd: true
            })

            // 当前访问页面次数大于等于设定的目标值
            if (window.viewedPageCount >= window.viewedCountTarget) {
              // 上报事件
              ttq.track('AddToWishlist')
              console.log('AddToWishlist: Viewed page count', window.viewedPageCount)
            }

            if ((window.readDuration / 60) >= 40) {
              // 阅读超过40篇文章并且点击过afc广告
              if (!window.isReportAddPaymentInfo) {
                ttq.track('AddPaymentInfo')
                window.isReportAddPaymentInfo = true
                setTrackStorage({
                  isReportAddPaymentInfo: true
                })
                console.log('AddPaymentInfo')
              }
            }

            if (window.isReportPlaceAnOrder) {
              // 阅读超过2篇文章并且点击过afc广告
              if (!window.isReportCompleteRegistration) {
                ttq.track("CompleteRegistration");
                window.isReportCompleteRegistration = true
                setTrackStorage({
                  isReportCompleteRegistration: true
                })
              }
            }

            if (window.viewedChapterCount >= 40) {
              // 阅读超过40篇文章并且点击过afc广告
              if (!window.isReportInitiateCheckout) {
                ttq.track("InitiateCheckout");
                window.isReportInitiateCheckout = true
                setTrackStorage({
                  isReportInitiateCheckout: true
                })
                console.log(
                  "InitiateCheckout: view more than 40 and click afc"
                );
              }

              // 并且停留超过40分钟
              if ((window.readDuration / 60) >= 40) {
                if (!window.isReportCompletePayment) {
                  ttq.track("CompletePayment");
                  window.isReportCompletePayment = true
                  setTrackStorage({
                    isReportCompletePayment: true
                  })
                  console.log(
                    "CompletePayment: view more than 49 and click afc and stay more than 40 minute"
                  );
                }
              }
            }

            // 点击穿插广告
            if (isClickVignetteAdInner) {
              // 是否看超过40篇文章
              if (window.viewedChapterCount >= 40) {
                if (!window.isReportSubscribe) {
                  ttq.track('Subscribe')
                  window.isReportSubscribe = true
                  setTrackStorage({
                    isReportSubscribe: true
                  })
                }
              }

              gtag("event", "click_vignette", {
                page: pathname === '/history' ? 'history_page' : pathname === '/' ? 'home_page' : 'content_page'
              });

              window.isClickVignetteAd = true

              setTrackStorage({
                isClickVignetteAd: true
              })
            }

            isClickVignetteAdInner = false
            isClickAfcInner = false
          }
        } else if ((document.visibilityState === 'visible')) {
          gtag('event', "page_visible")

          // 页面显示后重置点击广告变量
          isClickAfcInner = false
          isClickVignetteAdInner = false
        }
      })
    },

    track() {
      window.focus();
      window.addEventListener('blur', () => {
        const r = document.activeElement;
        console.log('blur element', r);
        if (r && r.tagName.toLowerCase() === 'iframe') {
          isClickAfcInner = true

          // 当前iframe广告的爷爷节点
          const parentNode = r?.parentNode?.parentNode;
          // 当前是不是插屏穿插广告
          // parentNode?.getAttribute('data-slotcar-interstitial')
          const isScreenAd = parentNode?.getAttribute('data-vignette-loaded');

          if (isScreenAd) {
            isClickVignetteAdInner = true
          }

          gtag('event', "click_iframe")

          setTimeout(() => {
            isClickAfcInner = false
            isClickVignetteAdInner = false
          }, 2000);
        }
        setTimeout(() => {
          window.focus();
        }, 0);
      })
    },

    async getGrayPercent() {
      const res = await fetch('https://9fungames.com/api/sitePercent?name=nbtfeeds', {
        method: 'GET',
      })
      const data = await res.json()
      grayPercent = data
    }
  }
}
</script>

<style>
@import url('./assets/css/common.css')
</style>
