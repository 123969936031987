<template>
    <!-- 阅读记录页 -->
    <div>
        <div class="history-header">
            <a class="back-btn" href="/" @click="goBack"></a>
            <h2 class="history-title">閱讀記錄</h2>
        </div>

        <div v-if="recordList.length > 0" class="history-book-list">
            <book-card v-for="book in recordList" :key="book.novelId" :data="book" direction="horizontal"
                class="history-book" :lastChapterId="book.chapterId" />
        </div>

        <p v-if="recordList.length === 0" class="history-empty">暫無閱讀記錄</p>
    </div>
</template>

<script>
import BookCard from '../components/book-card'
import { getHistoryRecord } from '../utils/chapter';

export default {
    components: {
        BookCard
    },

    data() {
        return {
            recordList: []
        }
    },

    methods: {
        loadHistoryRecord() {
            const data = getHistoryRecord()
            this.recordList = data
        },

        goBack() {
            gtag('click_button', {
                tab: 'history_page',
                back: true
            })
        }
    },

    mounted() {
        gtag('imp_page', {
            page: 'history_page'
        })

        this.loadHistoryRecord()
    }
};
</script>

<style>
.history-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 40px;
    text-align: center;
    background-color: #fff;
}

.back-btn {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    width: 72px;
    height: 72px;
    background: url('https://6gamehub.com/static/img/novel/assets/back-icon.webp') no-repeat;
    background-size: contain;
}

.history-title {
    font-weight: 600;
    font-size: 48px;
    color: #000;
    line-height: 48px;
}

.history-book-list {
    padding: 168px 40px 40px;
}

.history-book {
    margin-bottom: 44px;
}

.history-book:last-child {
    margin-bottom: 220px
}

.history-book img {
    width: 210px;
    height: 280px;
}

.history-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 44px;
}
</style>